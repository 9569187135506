function showModal(modalId) {
    jQuery('#' + modalId).modal('show');
}

function hideModal(modalId) {
    jQuery('#' + modalId).modal('hide');
}


function getElementByIdForParsley(id) {
    return jQuery('#' + id);
}

function refreshSelectPickerById(id) {
    jQuery('#' + id).selectpicker('refresh')
}

function refreshDOMContentLoadedEvent() {
    // Erstelle ein benutzerdefiniertes DOMContentLoaded-Event
    const domContentLoadedEvent = new Event('DOMContentLoaded');

    // Manuell das DOMContentLoaded-Event auslösen
    document.dispatchEvent(domContentLoadedEvent);
}

function checkFieldExistsAndSetValue(field, value, validation) {
    if (field && value !== null && value !== '') {
        if (validation) {
            field.classList.remove('parsley-error');
            field.classList.add('parsley-success');
        }

        field.value = value;
    }
}

function updateAllCKEditorInstances() {
    for (var instanceName in CKEDITOR.instances) {
        if (CKEDITOR.instances.hasOwnProperty(instanceName)) {
            CKEDITOR.instances[instanceName].updateElement();
        }
    }
}

// Berechnet das Alter eines Datums und liefert das Ergebnis bei lang=false in Form von "x" bzw. "x,y" oder (bei lang=true) "x Jahre" bzw. "x Jahre und y Monate". Bei Fehlern gibts null zurück
function bestimmeAlter(dateStr, mitMonaten = false, lang = true) {
    var datum = new Date(dateStr);
    var heute = new Date();

    // unvollständige Eingabe?
    if (isNaN(datum) || datum.getTime() > heute.getTime()) {
        return null;
    }

    // Differenz bilden
    var jahre = heute.getFullYear() - datum.getFullYear();
    var tage = heute.getDate() - datum.getDate();
    var monate = heute.getMonth() - datum.getMonth();

    if (monate < 0 || (monate == 0 && heute.getDate() < datum.getDate())) {
        jahre--;
        monate += 12;
    }
    if (tage < 0) {
        monate--;
    }

    return jahre + (lang ? (jahre == 1 ? " Jahr" : " Jahre") : '')
        + (mitMonaten ? (lang ? (monate == 0 ? "" : " und " + monate + (monate == 1 ? " Monat" : " Monate")) : ',' + Math.floor(10 * monate / 12)) : '');
}
