/******************************************************/
/* Methoden für Client-Server-Kommunikation (POST)    */
/******************************************************/

/*
 * Ruft mittels POST-Request eine Serverfunktion auf.
 *  Input:
 *  - url: Service-URL
 *  - action: Name der Action auf der Service-URL
 *  - parameters: JS-Objekt mit Übergabeparametern
 *  - onSuccess(response): Handler, der im Erfolgsfall aufgerufen wird.
 *  - onError(response): Optionaler Fehlerhandler, der mit oder ohne Response-Objekt aufgerufen wird.
 *  Beispiel:
 *  dlrg_DoPost("https://dlrg.net/application?page=service", "myAction", {a: 10, b: 20}, function(response) { msgbox(response);} );
 */
function dlrg_DoPost(url, action, parameters, onSuccess, onError) {
    var data = {action: action};
    for (var key in parameters) {
        data[key] = parameters[key];
    }

    jQuery.ajax({
        url: url, type: 'POST', data: data, success: function (jsonStr, status, jqXHR) {
            if (jqXHR && jqXHR.getResponseHeader("Content-Type").startsWith("text/html")) {
                jQuery.toast({
                    title: "Fehler bei Kommunikation mit Server",
                    subtitle: "5963927565",
                    content: mapHttpStatusCodeToText(jqXHR.status),
                    type: 'E',
                    delay: 10000
                });
                if (onError) {
                    onError();
                }
                return;
            }
            var response = jsonStr;
            if (!jsonStr instanceof Array) {
                response = jQuery.parseJSON(jsonStr);
            }
            /* Gibt es Fehlermeldungen? Gibt es Erfolgsmeldungen? */
            var hasErrors = false;
            var hasSuccess = false;
            if (response.messages && response.messages.length > 0) {
                response.messages.forEach(toast => {
                    jQuery.toast(toast);
                    hasErrors |= toast.type == "E";
                    hasSuccess |= toast.type != "E";
                });
            } else {
                /* gar keine Meldungen -> auch erfolgreich */
                hasSuccess = true;
            }
            /* onSuccess aufrufen, wenn erfolgreich */
            if (hasSuccess) {
                if (onSuccess) {
                    onSuccess(response.response);
                } else {
                    var format = jqXHR.getResponseHeader("X-Content-type");
                    var fileExtension = format.substr(format.indexOf("/") + 1);
                    /* wildes umkodieren, da atob den String irgendwie vergrößert und das Dokument damit kaputt macht... */
                    var blob = new Blob([Uint8Array.from(window.atob(response.response.replace(/\s/g, '')), c => c.charCodeAt(0))]);
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = action + "." + fileExtension;
                    link.type = format;
                    link.click();
                }
                /* onError nur aufrufen, wenn nicht erfolgreich _und_ eine onError-Methode existiert */
            } else if (hasErrors && onError) {
                onError(response.response);
            }
        }, error: function (jqXHr, statusText) {
            if (onError) {
                onError();
            } else {
                jQuery.toast({
                    title: "Fehler bei Kommunikation mit Server",
                    subtitle: "5963927568",
                    content: mapHttpStatusCodeToText(jqXHr.status),
                    type: 'E',
                    delay: 10000
                });
            }
        }
    });
}

/*
 * Ruft mittels POST-Request eine Serverfunktion mit einem HTML-Formular auf, falls Formular valide ist. Anderenfalls
 * werden auf der GUI die Validierungsfehler angezeigt.
 *  Input:
 *  - url: Service-URL
 *  - action: Name der Action auf der Service-URL
 *  - formularId: Die Objekt-Id des <form>-Tags, dessen Werte an den Server geschickt werden sollen
 *  - onSuccess(response): Handler, der im Erfolgsfall aufgerufen wird.
 *  - onError(response): Optionaler Fehlerhandler, der mit oder ohne Response-Objekt aufgerufen wird.
 *  Beispiel:
 *  dlrg_DoPostMitForm("https://dlrg.net/application?page=service", "myAction", "myFormId", function(response) { msgbox(response);} );
 */
function dlrg_DoPostMitForm(url, action, formularId, onSuccess, onError) {
    if (!formularId.startsWith("#") && !formularId.startsWith(".")) {
        formularId = "#" + formularId;
    }
    if (jQuery(formularId).parsley().validate()) {
        var parameters = {};
        jQuery(formularId).serializeArray().map(function (x) {
            parameters[x.name] = x.value;
        });
        dlrg_DoPost(url, action, parameters, onSuccess, onError);
    }
}

/*
 * Ruft mittels POST-Request eine Serverfunktion auf, die einen HTML-Response in das mit htmlElementReference identifizierte
 * HTML-Element befüllt.
 *  Input:
 *  - url: Service-URL
 *  - action: Name der Action auf der Service-URL
 *  - htmlElementReference: Die HTML-Id des Tags, das mit dem Response-HTML befüllt werden soll.
 *  - onSuccess(response): Optionaler Handler, der im Erfolgsfall aufgerufen wird.
 *  - onError(response): Optionaler Fehlerhandler, der mit oder ohne Response-Objekt aufgerufen wird.
 *  Beispiel:
 *  dlrg_DoHTMLPost("https://dlrg.net/application?page=service", "myAction", "myContentELement", function(response) { msgbox(response);} );
 */
function dlrg_DoHtmlPost(url, action, htmlElementReference, parameters, onSuccess, onError) {
    if (!htmlElementReference.startsWith("#") && !htmlElementReference.startsWith(".")) {
        htmlElementReference = "#" + htmlElementReference;
    }
    var contentElement = jQuery(htmlElementReference);
    dlrg_DoPost(url, action, parameters, function (data) {
        jQuery(contentElement).children().remove();
        jQuery(contentElement).html(data);
        if (onSuccess) {
            onSuccess(data);
        }
    }, function (data) {
        jQuery(contentElement).children().remove();
        if (onError) {
            onError(data);
        }
    });
}

/*
 * Liefert eine Liste von Werten aus einer Tabelle.
 * Parameter:
 * - tableObject: Tabellenobjekt
 * - ids (optional): Liste ausgewählter Ids (falls null, werden alle selektierten Tabellenwerte gelesen)
 * - idTargetField (optional): Wird dieser Wert angegeben, wird nur die entsprechende Tabellenspalte zurückgeliefert, ansonsten die ganze Zeile
 * - idFieldName (optional; default= 'id'): Gibt den Id-Spaltennamen an, über den gesucht wird. Wird nicht benötigt, wenn ids==null ist.
 */
function getValuesFromTable(tableObject, ids = null, idTargetField = null, idFieldName = "id") {
    let values = [];
    if (ids == null) {
        tableObject.bootstrapTable('getSelections').forEach(value => {
            values.push(idTargetField == null ? value : value[idTargetField]);
        });
    } else {
        tableObject.bootstrapTable('getData').forEach(value => {
            ids.forEach(id => {
                if (value[idFieldName] == id) {
                    values.push(idTargetField == null ? value : value[idTargetField]);
                }
            });
        });
    }
    return values;
}

/*
 * Liefert eine Liste von Ids aus einer Tabelle.
 * Parameter:
 * - tableObject: Tabellenobjekt
 * - idFieldName (optional; default= 'id'): Gibt den Id-Spaltennamen an, über den gesucht wird.
 * - fehlermeldung (optional; default=true): Liefert eine Fehlermeldung, wenn nichts ausgewäht wurde.
 *
 * Rückgabe: Array selektierter Ids
 */
function getSelectedIdsFromTable(tableObject, idFieldName = "id", fehlermeldung = true) {
    var checkedIds = [];
    tableObject.bootstrapTable('getSelections').forEach(value => {
        checkedIds.push(value[idFieldName]);
    });

    if (checkedIds.length > 0) {
        return checkedIds;
    }
    if (fehlermeldung !== false) {
        dlrg_MessageBox("Warnung", fehlermeldung === true ? "Diese Aktion kann nur ausgeführt werden, wenn Du mindestens einen " + "Eintrag ausgewählt hast." : fehlermeldung, "medium");
    }
    return null;
}
